import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CmuApi } from '../modules/auth/_models/CMUApi';

const API_ENDPLOINT = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class DonateService {
  constructor(private http: HttpClient) { }

  getSearchDonate(param: any): Observable<CmuApi> {
    let query = `?DonatorTypeID=${param.DonatorTypeID}`;
    query += `&JuristicTypeID=${param.JuristicTypeID}`;
    query += `&Name=${param.Name}`;
    query += `&Status=${param.Status}`;
    query += `&RDNumber=${param.RDNumber}`;

    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donator/search' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getDonateById(id: number): Observable<CmuApi> {
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donator/fullinfoViewModel?DonatorID=' + id)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getDonationGroup(param: string): Observable<CmuApi> {
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donation/searchProjects?OrganizationUserIdListString=' + param)
      .pipe(map(data => CmuApi.fromObject(data)));
  }


  getDonationGroup_OnSearch(param: any): Observable<CmuApi> {
    let query = `?OrganizationUserIdListString=${param.OrganizationUserIdListString}`;
    query += `&DonatorRdNumber=${param.DonatorRdNumber}`;
    query += `&DonatorFullName=${param.DonatorFullName}`;
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donation/searchProjects' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getSearchDetailGroup(param: any): Observable<CmuApi> {

    let query = `?DonationStartDate=${param.DonationStartDate}`;
    query += `&OrganizationUserIdListString=${param.OrganizationUserIdListString}`;
    query += `&FoundationId=${param.FoundationId}`;
    query += `&ProjectIdListString=${param.ProjectIdListString}`;
    query += `&DonationGroup=${param.DonationGroup}`;
    query += `&GroupRef=${param.refno}`;
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donation/getListGroupDetail?GroupRef=' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getDetailGroup(param: any): Observable<CmuApi> {
    let query = `?GroupRef=${param}`;
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donation/getListGroupDetail/' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getGroupReceipt(param: any): Observable<Blob> {
    let query = `?pDonationIdList=[${param}]`;
    return this.http
      .get(API_ENDPLOINT + 'report/getReceiptDonationGroup' + query, { responseType: 'blob' })
      .pipe(map(data => data))
  }

  getJuristic(param: any): Observable<CmuApi> {
    let query = `?JuristicTypeName=${param.JuristicTypeName}`;
    query += `&Description=${param.Description}`;
    query += `&Status=${param.Status}`;

    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'ref/juristictype/juristictypelist' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  addDonator(obj: object) {
    return this.http
      .post<CmuApi>(API_ENDPLOINT + 'donator', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  uploadExcel(obj: object) {
    return this.http
      .post<CmuApi>(API_ENDPLOINT + 'UploadFileDonationExcel', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  updateDonator(obj: object) {
    return this.http
      .put<CmuApi>(API_ENDPLOINT + 'donator', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  updateDonatorPassword(obj) {
    return this.http
      .put<CmuApi>(API_ENDPLOINT + 'donator/password', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
