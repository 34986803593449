<ng-container *ngIf="user$ | async as _user">
  <ng-container *ngIf="extrasUserDropdownStyle === 'dark'">
    <!--begin::Header-->
    <div
      class="d-flex align-items-center justify-content-between flex-wrap p-5 bgi-size-cover bgi-no-repeat rounded-top"
      style="background-color: #7883b7"
    >
      <div class="d-flex align-items-center mr-2">
        <!--begin::Symbol-->
        <div class="symbol bg-white-o-15 mr-3">
          <span
            class="symbol-label text-success font-weight-bold font-size-h4"
            >{{ _user.firstname | firstLetter }}</span
          >
        </div>
        <!--end::Symbol-->

        <!--begin::Text-->
        <div class="text-white m-0 flex-grow-1 mr-3 font-size-h5">
          {{ _user.firstname }} {{ _user.lastname }}
        </div>
        <!--end::Text-->
      </div>
    </div>
    <!--end::Header-->
  </ng-container>

  <!--begin::Nav-->
  <div class="navi navi-spacer-x-0 pt-5">
    <!--begin::Item-->
    <!-- <a class="navi-item px-8 cursor-pointer" routerLink="/user-profile">
      <div class="navi-link">
        <div class="navi-icon mr-2">
          <i class="flaticon2-calendar-3 text-success"></i>
        </div>
        <div class="navi-text">
          <div class="font-weight-bold">
            My Profile
          </div>
          <div class="text-muted">
            Account settings and more
            <span class="label label-light-danger label-inline font-weight-bold"
              >update</span
            >
          </div>
        </div>
      </div>
    </a> -->
    <!--end::Item-->

    <!--begin::Item-->
    <!-- <a class="navi-item px-8 cursor-pointer">
      <div class="navi-link">
        <div class="navi-icon mr-2">
          <i class="flaticon2-mail text-warning"></i>
        </div>
        <div class="navi-text">
          <div class="font-weight-bold">
            My Messages
          </div>
          <div class="text-muted">
            Inbox and tasks
          </div>
        </div>
      </div>
    </a> -->
    <!--end::Item-->

    <!--begin::Item-->
    <!-- <a class="navi-item px-8 cursor-pointer">
      <div class="navi-link">
        <div class="navi-icon mr-2">
          <i class="flaticon2-rocket-1 text-danger"></i>
        </div>
        <div class="navi-text">
          <div class="font-weight-bold">
            My Activities
          </div>
          <div class="text-muted">
            Logs and notifications
          </div>
        </div>
      </div>
    </a> -->
    <!--end::Item-->

    <!--begin::Item-->
    <!-- <a class="navi-item px-8 cursor-pointer">
      <div class="navi-link">
        <div class="navi-icon mr-2">
          <i class="flaticon2-hourglass text-primary"></i>
        </div>
        <div class="navi-text">
          <div class="font-weight-bold">
            My Tasks
          </div>
          <div class="text-muted">
            latest tasks and projects
          </div>
        </div>
      </div>
    </a> -->
    <!--end::Item-->

    <!--begin::Footer-->
    <div class="navi-separator mt-3"></div>
    <div class="navi-footer px-8 py-5">
      <a
        class="btn btn-light-primary font-weight-bold cursor-pointer"
        (click)="logout()"
        >Log Out</a
      >
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Nav-->
</ng-container>
