<!-- <p>footer-home works!</p> -->
<!-- begin:: footer -->
<section class="footer" id="footer" >
    <div class="bg-dark">
        <div class="container pt-20">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <a href="javascript:;"><img src="assets/media/home/logo_footer.png" alt="ITSC CMU Logo" class="w-75"></a>
                </div>
                <div class="col-md-6 col-sm-12 d-flex justify-content-md-end">
                    <div class="mt-5 ml-3">
                        <span class="text-white">Copyright © 2019 Chiang Mai University, All rights reserved.</span>
                        <br>
                        <span class="text-dark-50"> by Information Technology Service Center</span>	
                    </div>
                </div>
                
            </div>
        </div>
    
        <div class="f2-middle m-t-30 footer3">
            <div class="container">
                <div class="row">
                    <!-- Column -->
                    <div class="col-lg-4 col-md-6">
                        <div class="my-20 mx-10">
                            <h3 class="text-white"><span>ติดต่อติดต่องานการเงินและบัญชี (กองคลัง)</span></h3>
                            <h6 class="text-white"><span>(สอบถามข้อมูลใบเสร็จรับเงิน)</span></h6>
                            <ul class="text-dark-50 pl-4">
                                <li class="mb-5 font-size-h6"><a>
                                    กองคลัง สำนักงานมหาวิทยาลัย 239 ถนนห้วยแก้ว <br>
                                    ต.สุเทพ อ.เมือง จ.เชียงใหม่ 50200
                                    <br>
                                    โทรศัพท์ : 0-5394-1142, 0-5394-1143
                                    <br>
                                    อีเมล : donation@cmu.ac.th
                                </a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- Column -->
                    <!-- Column -->
                    <!-- Column -->
    
                    <div class="col-lg-3 col-md-6">
                        <div class="my-20 mx-10">
                            <h3 class="text-white"><span id="wucFooter_lbHeader_Service">บริการสำคัญ</span></h3>
                            <ul class="text-dark-50 pl-4">
                                <li class="mb-5"><a class="text-dark-50 font-size-h6" href="assets/media/home/CMUPhoneBook.pdf" target="_new">สมุดโทรศัพท์มหาวิทยาลัยเชียงใหม่</a></li>
                                <li class="mb-5"><a class="text-dark-50 font-size-h6" href="assets/media/home/BrochureCMU-Map2017.pdf" target="_new">แผนที่มหาวิทยาลัยเชียงใหม่</a></li>
                                <li class="mb-5"><a class="text-dark-50 font-size-h6" href="home-CMU-Donate.html" target="_new">การบริจาค</a></li>
                                <li class="mb-5"><a class="text-dark-50 font-size-h6" href="https://portal.office.com" target="_new">CMU MAIL</a></li>
                                <li class="mb-5"><a class="text-dark-50 font-size-h6" href="https://mis.cmu.ac.th" target="_new">CMU MIS</a></li>
                                <li class="mb-5"><a class="text-dark-50 font-size-h6" href="https://cmubackoffice.mis.cmu.ac.th" target="_new">สำหรับเจ้าหน้าที่</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- Column -->
                    <!-- Column -->
                    <div class="col-lg-5 col-md-6">
                        <div class="my-20 mx-10">
                            <h3 class="text-white"><span >เยี่ยมชมมหาวิทยาลัยเชียงใหม่</span></h3>
                            <ul class="text-dark-50 pl-4">
                                <li class="mb-5"><a class="text-dark-50 font-size-h6" href="https://cmu.ac.th/360/" target="_new">CMU 360 องศา</a></li>
                            </ul>
                        </div>

                        <div class="my-20 mx-10">
                            <h5 class="text-white"><span>ช่องทางสื่อสาร</span></h5>
                            <p class="text-dark-50">Website : <a href="https://www.cmu.ac.th" class="text-dark-50 font-size-h6">https://www.cmu.ac.th </a></p>
                            <div class="round-social light my-10">
                                <span>
                                    <a 
                                    href="https://www.facebook.com/cmuofficial/" 
                                    target="_new" 
                                    class="link" 
                                    title="Facebook"
                                >
                                    <i class="socicon-facebook text-dark icon-lg"></i>
                                </a>
                                </span>
                                <span>
                                    <a 
                                    href="https://www.youtube.com/channel/UCwlSb-f1YEwYG9s0o7LWRnA" 
                                    class="link" 
                                    target="_new" 
                                    title="Youtube"
                                >
                                    <i class="socicon-youtube text-dark icon-lg"></i>
                                </a>
                                </span>
                                <span>
                                    <a 
                                    href="https://twitter.com/cmuofficial_tw?s=09" 
                                    class="link" 
                                    target="_new" 
                                    title="Twitter"
                                >
                                    <i class="socicon-twitter text-dark icon-lg"></i>
                                </a>
                                </span>
                                <span>
                                    <a 
                                    href="https://www.instagram.com/cmuofficial.ig/" 
                                    class="link" 
                                    target="_new" 
                                    title="Instagram"
                                >
                                    <i class="socicon-instagram text-dark icon-lg"></i>
                                </a>
                                </span>
                                <span>
                                    <a 
                                    href="https://voc.cmu.ac.th/" 
                                    class="link" 
                                    target="_new" 
                                    title="Voice of Customer"
                                >
                                    <i class="fab fa-rocketchat text-dark icon-lg"
                                    ></i>
                                </a>
                                </span>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</section>
<!-- end:: footer -->