<!--begin::Topbar-->
<ng-container *ngIf="extraSearchDisplay">
  <ng-container *ngIf="extrasSearchLayout === 'offcanvas'">
    <!--begin::Search-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-hover-transparent-white btn-lg btn-dropdown mr-1" id="kt_quick_search_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl"></span>
      </div>
    </div>
    <!--end::Search-->
  </ng-container>

  <ng-container *ngIf="extrasSearchLayout === 'dropdown'" id="kt_quick_search_toggle">
    <div class="dropdown" id="kt_quick_search_toggle" autoClose="outside" ngbDropdown>
      <!--begin::Toggle-->
      <div class="topbar-item" ngbDropdownToggle>
        <div class="btn btn-icon btn-hover-transparent-white btn-lg btn-dropdown mr-1">
          <span [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'" cacheSVG="true"
            class="svg-icon svg-icon-xl"></span>
        </div>
      </div>
      <!--end::Toggle-->

      <!--begin::Dropdown-->
      <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg" ngbDropdownMenu>
        <app-search-dropdown-inner></app-search-dropdown-inner>
      </div>
      <!--end::Dropdown-->
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasNotificationsDisplay">
  <ng-container *ngIf="extrasNotificationsLayout === 'offcanvas'">
    <!--begin::Notifications-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary"
        id="kt_quick_notifications_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl"></span>
        <span class="pulse-ring"></span>
      </div>
    </div>
    <!--end::Notifications-->
  </ng-container>

  <ng-container *ngIf="extrasNotificationsLayout === 'dropdown'">
    <!--begin::Notifications-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary">
          <span [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'" cacheSVG="true"
            class="svg-icon svg-icon-xl"></span>
          <span class="pulse-ring"></span>
        </div>
      </div>
      <!--end::Toggle-->

      <!--begin::Dropdown-->
      <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg">
        <form>
          <app-notifications-dropdown-inner></app-notifications-dropdown-inner>
        </form>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Notifications-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasQuickActionsDisplay">
  <ng-container *ngIf="extrasQuickActionsLayout === 'offcanvas'">
    <!--begin::Quick Actions-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1" id="kt_quick_actions_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl"></span>
      </div>
    </div>
    <!--end::Quick Actions-->
  </ng-container>
  <ng-container *ngIf="extrasQuickActionsLayout === 'dropdown'">
    <!--begin::Quick Actions-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1">
          <span [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'" cacheSVG="true"
            class="svg-icon svg-icon-xl"></span>
        </div>
      </div>
      <!--end::Toggle-->
      <!--begin::Dropdown-->
      <div class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg" ngbDropdownMenu>
        <app-quick-actions-dropdown-inner></app-quick-actions-dropdown-inner>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Quick Actions-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasCartDisplay">
  <ng-container *ngIf="extrasCartLayout === 'offcanvas'">
    <!--begin::Cart-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1" id="kt_quick_cart_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl"></span>
      </div>
    </div>
    <!--end::Cart-->
  </ng-container>
  <ng-container *ngIf="extrasCartLayout === 'dropdown'">
    <!--begin::Cart-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1">
          <span [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'" cacheSVG="true"
            class="svg-icon svg-icon-xl"></span>
        </div>
      </div>
      <!--end::Toggle-->
      <!--begin::Dropdown-->
      <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-xl dropdown-menu-anim-up">
        <form>
          <app-cart-dropdown-inner></app-cart-dropdown-inner>
        </form>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Cart-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasLanguagesDisplay">
  <app-language-selector style="margin-top: 20px"></app-language-selector>
</ng-container>

<ng-container *ngIf="extrasUserDisplay">
  <ng-container *ngIf="extrasUserLayout === 'dropdown'">
    <!--begin::User-->
    <ng-container *ngIf="user$">
      <div class="dropdown" ngbDropdown placement="bottom-right">
        <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
          <div class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
            <span class="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">สวัสดี ,</span>
            <span
              class="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">{{ user$.firstname }}
            </span>
          </div>
        </div>
        <!--end::Toggle-->
        <!--begin::Dropdown-->
        <div ngbDropdownMenu
          class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <app-user-dropdown-inner></app-user-dropdown-inner>
        </div>
      </div>
      <!--end::Dropdown-->
    </ng-container>
    <!--end::User-->
  </ng-container>
</ng-container>

<!--end::Topbar-->