import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }


  static getValidatorErrorMessage(validatorName: any,validatorValue?: any, nameField?: string){
    const messages = {
      'required' : `จำเป็นต้องใส่ "${nameField}"`,
      'email' : `ใส่ได้เฉพาะ "${nameField}"`,
      'minlength' : `"${nameField}" จำเป็นต้องมีอย่างน้อย ${validatorValue.requiredLength} ตัว`,
      // 'mustMatch' : `"${nameFieldConfirm}" กับ "${nameField}" ไม่ตรงกัน`
    };
    // console.log(validatorName)

    for (const [key, value] of Object.entries(messages)) {
      if (key === validatorName){
        return value;
      }
    }

    return ;
  }
}


