import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CmuApi } from '../modules/auth/_models/CMUApi';

const API_ENDPLOINT = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private http: HttpClient) { }

  getRefAsset(Level1ID: string, Level2ID: string): Observable<CmuApi> {
    const query = `?Level1ID=${Level1ID}&Level2ID=${Level2ID}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'ref/asset/grouptype' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getAssetgrouptypeinfo(Level1ID: string, Level2ID: string): Observable<CmuApi> {
    const query = `?Level1ID=${Level1ID}&Level2ID=${Level2ID}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'ref/asset/assetgrouptypeinfo' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getAssetgrouptypebyid(AssetTypeID: string): Observable<CmuApi> {
    const query = `?AssetTypeID=${AssetTypeID}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'ref/asset/grouptypebyid' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  addRefAsset(obj: object): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'ref/asset/grouptype', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateRefAsset(obj: Object): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'ref/asset/grouptype', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateRefAssetStatus(obj: Object): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'ref/asset/grouptype/setstatus', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }
}
