import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class MemberAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const jwtToken = (userDetails) ? userDetails.jwtToken : null;
    const roleID = (userDetails) ? userDetails.roleID : null;
    const isCustomer = this.authService.isCustomer(userDetails);
    if (jwtToken && isCustomer) {
      // logged in so return true
      return true;
    } else {
      this.authService.home();
      return false;
    }
  }
}
