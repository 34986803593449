import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class PointAuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const jwtToken = (userDetails) ? userDetails.jwtToken : null;
    const roleID = (userDetails) ? userDetails.roleID : null;

    if (jwtToken && (roleID === 0 || roleID === 4 || roleID === 5 || roleID === 8)) {
      return true;
    } else if (jwtToken && !(roleID === 0 || roleID === 4 || roleID === 5 || roleID === 8)) {
      this.authService.dashboard();
      return true;
    } else {
      this.authService.home();
      return false;
    }
    
  }
}