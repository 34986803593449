import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CmuApi } from '../modules/auth/_models/CMUApi';

const API_ENDPLOINT = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class FoundationService {
  constructor(private http: HttpClient) { }

  getSearchFoundation(FoundationName: string, FoundationTypeID: string, Status: string): Observable<CmuApi> {
    const query = `?FoundationName=${FoundationName}&FoundationTypeID=${FoundationTypeID}&Status=${Status}`
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'foundation' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getFoundationLanding(Limit: number): Observable<CmuApi> {
    const query = `?Limit=${Limit}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'foundation/landingPage' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getFoundationInfoLanding(FoundationId: number): Observable<CmuApi> {
    const query = `?FoundationId=${FoundationId}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'foundation/fullinfo/landing' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateStatusPromote(obj: any, founId: number): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'foundation/' + founId + '/setpromote', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  // foundation/fullinfo/landing

  addFoundation(obj: Object): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'foundation', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateFoundation(obj: Object): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'foundation', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateFoundationStatus(obj: Object): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'foundation/setstatus', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }
}
