import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CmuApi } from '../modules/auth/_models/CMUApi';

const API_ENDPLOINT = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  getUserRole(): Observable<CmuApi> {
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'user/role')
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getUserRoleByOrgAndKey(key: string, id: string): Observable<CmuApi> {
      return this.http
      .post<CmuApi>(API_ENDPLOINT + 'employee/search', {KeywordNameSurname: key, OrganizationID: id})
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getUserRoleByRoleAndKey(key: string, roleId: number): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'userrole/search', {name: key, role_id: roleId})
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getOrganizationByUser(userid: string): Observable<CmuApi> {
    const url = API_ENDPLOINT + 'userrole/organization';
    const query = `?UserID=${userid}`;
    return this.http
    .get<CmuApi>(url + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getTransection(): Observable<CmuApi> {
    const url = API_ENDPLOINT + 'member/donationhistory';
    return this.http
    .get<CmuApi>(url)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getProfile(): Observable<CmuApi> {
    const url = API_ENDPLOINT + 'member/donatorprofile';
    return this.http
    .get<CmuApi>(url)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  addUserRole(obj: object): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'user/role', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateUserRole(obj: object): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'user/role', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  registerMember(obj: object): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'register', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

 loginMember(obj: object): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'memberlogin', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  forgotPassword(email: string): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'member/forgotpassword', {email: email})
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  resetPassword(obj: object): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'member/resetpassword', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }
}
