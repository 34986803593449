<!-- <p>header-home works!</p> -->
<!-- ?begin::nav -->
<nav class="navbar navbar-expand-lg navbar-light fixed-top">
    <div class="img-logo-donate">
        <a routerLink="/home">
            <img alt="Logo" src="assets/media/CMU_DONATE_Logo.png" />
        </a>
    </div>

    <!-- ?begin::buttom-nav-mobile -->
    <button class="navbar-toggler border-0" 
        type="button" 
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="header11"
    >
        <span class="navbar-toggler-icon"></span>
    </button>
    <!-- *end::buttom-nav-mobile -->

    <div class="collapse navbar-collapse  flex-column" id="header11" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav ml-auto " >
            <li class="nav-item m-xxl-3 m-md-1" style="width: 5rem;">
                <div>
                <a  pRipple
                    class="nav-link text-dark-50  font-size-h6-xxl font-size-sm-md " 
                    [routerLink]="['/home']" 
                    routerLinkActive="font-weight-bolder"
                >หน้าแรก</a>
                </div>
            </li>
            <li class="nav-item m-xxl-3 m-md-1" style="width: 10rem;">
                <a  pRipple
                    class="nav-link text-dark-50 font-size-h6-xxl font-size-sm-md" 
                    [routerLink]="['/home/step']" 
                    routerLinkActive="font-weight-bolder"
                >ขั้นตอนการบริจาค</a>
            </li>
            <li class="nav-item m-xxl-3 m-md-1" style="width: 7.5rem;">
                <a  pRipple
                    class="nav-link text-dark-50 font-size-h6-xxl font-size-sm-md" 
                    [routerLink]="['/home/privilege']" 
                    routerLinkActive="font-weight-bolder"
                >สิทธิประโยชน์</a>
            </li>
            <li class="nav-item m-xxl-3 m-md-1" style="width: 8rem;">
                <a  pRipple
                    class="nav-link text-dark-50 font-size-h6-xxl font-size-sm-md" 
                    [routerLink]="['/home/project-home']" 
                    routerLinkActive="font-weight-bolder"
                >ค้นหาโครงการ</a>
            </li>
            <li class="nav-item m-xxl-3 m-md-1" style="width: 7.5rem;">
                <a  pRipple target="_blank"
                    class="nav-link text-dark-50 font-size-h6-xxl font-size-sm-md" 
                    [routerLink]="['/home/search-receipt']" 
                    routerLinkActive="font-weight-bolder"
                >ค้นหาใบเสร็จ</a>
            </li>
            <li class="nav-item m-xxl-3 m-md-1" style="width: 6rem;cursor: pointer;scroll-behavior: smooth;">
                <a  pRipple
                    id="call"
                    class="nav-link text-dark-50 font-size-h6-xxl font-size-sm-md"
                    (click)="scrollToBottom()"
                >ติดต่อเรา</a>
            </li>
            <!-- style="scroll-behavior: smooth;" -->
            <!-- ?begin::ลงทะเบียน -->
            <li class="nav-item my-3 " *ngIf="user$ === null">
                <a 
                    [routerLink]="['/auth/registration']" 
                    class="btn mr-2 btn-outline-success font-size-h6"
                >ลงทะเบียน</a>
            </li>
            <!-- *end::ลงทะเบียน -->

            <!-- ?begin::เข้าสู่ระบบ  -->
            <li class="nav-item my-3 " *ngIf="user$ === null">
                <a 
                    [routerLink]="['/auth/login']"
                    class="btn font-weight-bold btn-success font-size-h6"
                >เข้าสู่ระบบ</a>
                <!-- pRipple -->
            </li>
            <!-- *begin::เข้าสู่ระบบ  -->

            <!-- ?begin::เข้าสู่ระบบ  -->
            <li class="nav-item dropdown" *ngIf="user$" style="padding-right: 60px;">
                <div class="dropdown" ngbDropdown placement="bottom-right" [autoClose]="true">
                    <!--begin::Toggle-->
                    <div
                      ngbDropdownToggle
                      class="topbar-item"
                      data-toggle="dropdown"
                      data-offset="10px,0px"
                    >
                      <div class="btn btn-dropdown font-weight-bold btn-white font-size-h6 mt-1">
                        สวัสดี ,{{ user$.firstname }}
                      </div>
                    </div>
                    <!--begin::Dropdown-->
                    <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
                        <ul class="navi navi-hover">
                             <li class="navi-item py-2" *ngIf="!isCustomer"> <!-- เช็คแสดงเฉพาะเจ้าหน้าที่ -->
                                <a [routerLink]="['/dashboard']" class="navi-link"><span class="navi-text">สำหรับเจ้าหน้าที่</span></a>
                            </li>
                            <li class="navi-item py-2" *ngIf="isCustomer">
                                <a [routerLink]="['/member/profile']" class="navi-link"><span class="navi-text">บัญชีผู้ใช้งาน</span></a>
                            </li>
                            <li class="navi-item py-2">
                                <a class="navi-link" (click)="logout()" ><span class="navi-text">Log Out</span></a>
                            </li>

                        </ul>
                    </div>
                </div>
              </li>
            <!-- *begin::เข้าสู่ระบบ  -->
        </ul>
        
    </div>
</nav>
<!-- *end::nav -->


