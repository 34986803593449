import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserModel, AuthService } from 'src/app/modules/auth/index.js';
import KTScrolltop from '../../../../../../assets/js/components/scrolltop.js';
@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss']
})
export class HeaderHomeComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  user$: UserModel;
  isCollapsed = true;
  scrollTop: any;
  isCustomer: any;
  

  constructor(private auth: AuthService) { 
    this.user$ = this.auth.getUserDetail();
    this.isCustomer = this.auth.isCustomer(this.user$);
  }

  ngOnInit(): void {
  }

  scrollToBottom(): void {
    const scrollingElement = (document.scrollingElement || document.body);
    document.documentElement.style.scrollBehavior = 'smooth';
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
    document.documentElement.style.scrollBehavior = 'auto';
  }

  logout() {
    this.auth.logout();
    document.location.reload();
  }
}
