import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CmuApi } from '../modules/auth/_models/CMUApi';

const API_ENDPLOINT = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient) { }

  getOrganizationAll(): Observable<CmuApi> {
    const url = API_ENDPLOINT + 'userrole/organization';
    return this.http
    .get<CmuApi>(url)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getOrganizationChild(OrgID: string): Observable<CmuApi> {
    const url = API_ENDPLOINT + 'ref/organization/' + OrgID + '/child';
    return this.http
    .get<CmuApi>(url)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getOrganizationDB(OrganizationName: string, OrganizationTypeID: string, Status: string): Observable<CmuApi> {
    const url = API_ENDPLOINT + 'ref/organizationDB';
    const query = `?OrganizationName=${OrganizationName}&OrganizationTypeID=${OrganizationTypeID}&Status=${Status}`;
    return this.http
    .get<CmuApi>(url + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }
  
  updateOrganizationMappingFinance(obj: object){
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'ref/organization/updateMappingFinance', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }
}
