import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { AuthService } from '../modules/auth';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const token = (currentUser) ? currentUser.jwtToken : null;
        const roleID = (currentUser) ? currentUser.roleID : null;
        const newRequest = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + token)
        });

        return next.handle(newRequest).pipe(
            tap((ev: HttpEvent<any>) => { }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.authService.logout();
                    if (roleID === 11) {
                        this.authService.home();
                    } else {
                        this.router.navigate(['/auth/login']);
                    }
                }
                return throwError(error);
            })
        );
    }
}
