import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CmuApi } from '../modules/auth/_models/CMUApi';

const API_ENDPLOINT = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  constructor(private http: HttpClient) { }

  getRefPayment(PaymentMethodName: string, Description: string, Status: string): Observable<CmuApi> {
    const query = `?PaymentMethodName=${PaymentMethodName}&Description=${Description}&Status=${Status}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'ref/payment/paymentmethodlist' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getRefPaymentmethoddropdown(StatusForDonation: string, StatusQR: string): Observable<CmuApi> {
    const query = `?StatusForDonation=${StatusForDonation}&StatusQR=${StatusQR}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'ref/payment/methoddropdown' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  addRefPayment(obj: object): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'ref/payment', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateRefPayment(obj: object): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'ref/payment', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateRefPaymentStatus(obj: object): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'ref/payment/setstatus', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }
}
