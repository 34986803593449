import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CmuApi } from '../modules/auth/_models/CMUApi';

const API_ENDPLOINT = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class DonationService {
  constructor(private http: HttpClient) { }

  crateDanation(obj: object) {
    return this.http
      .post<CmuApi>(API_ENDPLOINT + 'donation', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getReceipt(DonationID: number): Observable<Blob> {
    const query = `?DonationID=${DonationID}`;

    return this.http
      .get(API_ENDPLOINT + 'getReceipt' + query, { responseType: 'blob' })
      .pipe(map(data => data));
  }

  getThankCard(DonationID: number): Observable<Blob> {
    const query = `?DonationID=${DonationID}`;

    return this.http
      .get(API_ENDPLOINT + 'report/thankletter' + query, { responseType: 'blob' })
      .pipe(map(data => data));
  }

  getSearch(p: any): Observable<CmuApi> {

    let query = `?DonationStartDate=${p.DonationStartDate}`;
    query += `&DonationEndDate=${p.DonationEndDate}`;
    query += `&FoundationId=${p.FoundationId}`;
    query += `&ProjectIdListString=${p.ProjectId}`;
    query += `&PaymentMethodIdListString=${p.PaymentMethodId}`;
    query += `&OrganizationUserIdListString=${p.OrganizationUserIdListString}`;
    query += `&DonatorRdNumber=${p.DonatorRdNumber}`;
    query += `&DonatorName=${p.DonatorName}`;

    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donation/search' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getDonationPointAll(): Observable<CmuApi> {
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donationpoint/all')
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getDonationPointUser(OrganizationId) {
    const query = `?OrganizationID=${OrganizationId}`;
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donationpoint/organizationId' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  addDonationPoint(obj: Object): Observable<CmuApi> {
    return this.http
      .post<CmuApi>(API_ENDPLOINT + 'donationpoint/add', obj)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getDonationPointById(OrganizationId: number, OrganizationPointId: number): Observable<CmuApi> {
    const query = `?OrganizationId=${OrganizationId}&OrganizationPointId=${OrganizationPointId}`;
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donationpoint/info' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateDonationPoint(obj: object) {
    return this.http
      .put<CmuApi>(API_ENDPLOINT + 'donationPoint', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  deleteDonationPoint(OrganizationId: number, OrganizationPointId: number) {
    const query = `?OrganizationId=${OrganizationId}&OrganizationPointId=${OrganizationPointId}`;
    return this.http
      .delete<CmuApi>(API_ENDPLOINT + 'donationPoint' + query)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getOrganizationPointUser(OrganizationId) {
    const query = `?OrganizationID=${OrganizationId}`;
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'ref/GetOrganizationPointUser' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getOrganizationPointUserAdmin(OrganizationId) {
    const query = `?OrganizationID=${OrganizationId}`;
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'ref/GetOrganizationPointUserAdmin' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateOrganizationUserAndPoint(obj: object) {
    return this.http
      .put<CmuApi>(API_ENDPLOINT + 'user/updateOrganizationUserAndPoint', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  receiptSendMail(obj: Object): Observable<CmuApi> {
    return this.http
      .post<CmuApi>(API_ENDPLOINT + 'donation/receiptsendmail', obj)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getSearchQRTransaction(p: any): Observable<Blob> {

    let query = `?DonationStartDate=${p.DonationStartDate}`;
    query += `&DonationEndDate=${p.DonationEndDate}`;
    query += `&FoundationID=${p.FoundationId}`;
    query += `&ProjectID=${p.ProjectId}`;
    query += `&Amount=${p.amountSelected || 0}`;

    return this.http
      .get(API_ENDPLOINT + 'getQRTransaction' + query, { responseType: 'blob' })
      .pipe(map(data => data));
  }

  getSearchGroup(param: any) {
    let query = `?DonationStartDate=${param.DonationStartDate}`;
    query += `&DonationEndDate=${param.DonationEndDate}`;
    query += `&PaymentMethodIdListString=${param.PaymentMethodIdListString}`;
    query += `&ProjectIdListString=${param.ProjectIdListString}`;
    query += `&DonationGroup=${param.DonationGroup}`
    query += `&FoundationId=${param.FoundationId}`;

    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donation/searchProjects' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  setReceiptStatus(obj: object) {
    return this.http
      .put<CmuApi>(API_ENDPLOINT + 'donation/setreceiptstatus', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  setReceiptStatusGroup(obj) {
    return this.http
      .put<CmuApi>(API_ENDPLOINT + 'donation/setreceiptstatusgroupall', obj)
      .pipe(
        map(data => CmuApi.fromObject(data)),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getDonationGroupName(): Observable<CmuApi> {
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donation/getDonationGroupName')
      .pipe(map(data => CmuApi.fromObject(data)));
  }

  getDonationProjectByName(DonationGroup) {
    const query = `?DonationGroup=${DonationGroup}`;
    return this.http
      .get<CmuApi>(API_ENDPLOINT + 'donation/getDonationProjectName' + query)
      .pipe(map(data => CmuApi.fromObject(data)));
  }

}
