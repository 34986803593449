import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// angular
import { NumbersOnlyDirective } from '../services/directive/numbers-only.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RippleModule } from 'primeng/ripple';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { SliderModule } from 'primeng/slider';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { CRUDTableModule } from '../_metronic/shared/crud-table';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ChipModule } from 'primeng/chip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DividerModule } from 'primeng/divider';
import { StepsModule } from 'primeng/steps';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { TooltipModule } from 'primeng/tooltip';
import { ErrorMessageComponent } from '../pages/_layout/components/error-message/error-message.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputNumberModule } from 'primeng/inputnumber';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HeaderHomeComponent } from '../modules/home/components/layout/header-home/header-home.component';
import { FooterHomeComponent } from '../modules/home/components/layout/footer-home/footer-home.component';

@NgModule({
  declarations: [ NumbersOnlyDirective, ErrorMessageComponent,  HeaderHomeComponent, 
FooterHomeComponent ],
  imports: [
    NgApexchartsModule,
    ScrollPanelModule,
    CommonModule,
    ChipModule,
    ConfirmPopupModule,
    MatToolbarModule, 
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    CRUDTableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    RippleModule,
    FileUploadModule,
    InputMaskModule,
    TooltipModule,
    CheckboxModule,
    MenuModule,
    StepsModule,
    DividerModule,
    DynamicDialogModule,
    InputTextareaModule,
    RadioButtonModule,
    InputSwitchModule,
    TagModule,
    ConfirmDialogModule,
    NgbModule,
    InputNumberModule,
    ProgressSpinnerModule
  ],
  exports: [
    HeaderHomeComponent,
    FooterHomeComponent,
    NgApexchartsModule,
    ScrollPanelModule,
    ErrorMessageComponent,
    NumbersOnlyDirective,
    ChipModule, 
    ConfirmPopupModule,
    MatToolbarModule, 
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    TableModule,
    FormsModule, 
    ReactiveFormsModule,
    CRUDTableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    RippleModule,
    InputMaskModule,
    TooltipModule,
    CheckboxModule,
    MenuModule,
    StepsModule,
    DividerModule,
    DynamicDialogModule,
    InputTextareaModule,
    RadioButtonModule,
    InputSwitchModule,
    TagModule,
    FileUploadModule,
    ConfirmDialogModule,
    NgbModule,
    InputNumberModule,
    ProgressSpinnerModule
  ],
})

export class SharedModule { }
