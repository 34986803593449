<!-- compact -->
<ng-container *ngIf="footerLayout === 'compact'">
  <!--begin::Container-->
  <div
    [ngClass]="footerContainerCSSClasses"
    class="d-flex flex-column flex-md-row align-items-center justify-content-between"
  >
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
      <span class="text-muted font-weight-bold mr-2"
        >{{ currentYear }} &copy;</span
      >
      <a
        href="#"
        target="_blank"
        class="text-dark-75 text-hover-primary"
        >CMU Donation</a
      >
    </div>
    <!--end::Copyright-->

    <!--begin::Nav-->
    <div class="nav nav-dark order-1 order-md-2">
      <a
        href="#"
        target="_blank"
        class="nav-link pr-3 pl-0"
        >About</a
      >
      <a
        href="#"
        target="_blank"
        class="nav-link px-3"
        >Team</a
      >
      <a
        href="#"
        target="_blank"
        class="nav-link pl-3 pr-0"
        >Contact</a
      >
    </div>
    <!--end::Nav-->
  </div>
  <!--end::Container-->
</ng-container>
<!-- extended -->
<ng-container *ngIf="footerLayout === 'extended'">
  <!--begin::Container-->
  <div [ngClass]="footerContainerCSSClasses" class="container py-lg-18 py-8">
    <!--begin::Row-->
    <div class="row">
      <!--begin::Col-->
      <div class="col-lg-4 my-lg-0 my-5">
        <h4 class="text-white pb-3">About</h4>
        <p class="m-0 text-white opacity-45">
          Lorem Ipsum is simply dummy text of the printing<br />
          and typesetting and typesetting industry has been the <br />
          industry's standard dummy text ever since the 1500s,<br />
          when an unknown printer took a galley of type.
        </p>
      </div>
      <!--end::Col-->

      <!--begin::Col-->
      <div class="col-lg-4 my-lg-0 my-5">
        <h4 class="text-white pb-3">Quick Links</h4>
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column mr-18">
            <a href="#" class="text-white opacity-55 text-hover-primary"
              >General Reports</a
            >
            <a href="#" class="py-2 text-white opacity-55 text-hover-primary"
              >Dashboart Widgets</a
            >
            <a href="#" class="text-white opacity-50 text-hover-primary"
              >Custom Pages</a
            >
          </div>
          <div class="d-flex flex-column">
            <a href="#" class="text-white opacity-55 text-hover-primary"
              >User Setting</a
            >
            <a href="#" class="py-2 text-white opacity-55 text-hover-primary"
              >Custom Pages</a
            >
            <a href="#" class="text-white opacity-55 text-hover-primary"
              >Intranet Settings</a
            >
          </div>
        </div>
      </div>
      <!--end::Col-->

      <!--begin::Col-->
      <div class="col-lg-4 my-lg-0 my-5">
        <h4 class="text-white pb-3">Get In Touch</h4>
        <form class="rounded" style="background-color: rgba(0, 0, 0, 0.2)">
          <div class="input-group p-2 align-items-center">
            <input
              type="text"
              class="form-control rounded-right border-0 bg-transparent text-white opacity-80"
              placeholder="Enter Your Email"
            />
            <div class="input-group-append p-0 mr-1">
              <button
                class="btn btn-fh btn-sm btn-primary px-6 rounded-left"
                type="button"
              >
                Join
              </button>
            </div>
          </div>
        </form>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->
  </div>
  <!--end::Container-->

  <!--begin::Container-->
  <div class="separator separator-solid opacity-7"></div>
  <!--end::Container-->

  <!--begin::Container-->
  <div class="container py-8" [ngClass]="footerContainerCSSClasses">
    <div
      class="d-flex align-items-center justify-content-between flex-lg-row flex-column"
    >
      <!--begin::Copyright-->
      <div class="d-flex align-items-center order-lg-1 order-2">
        <img
          alt="Logo"
          src="./assets/media/logos/logo-letter-1.png"
          class="logo-sticky max-h-35px"
        />
        <span class="text-muted font-weight-bold mx-2"
          >{{ currentYear }} ©</span
        >
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="text-primary text-hover-primary"
          >Keenthemes</a
        >
      </div>
      <!--end::Copyright-->

      <!--begin::Nav-->
      <div class="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="text-white opacity-55 pl-0 text-hover-primary"
          >Purchase Lisence</a
        >
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="text-white opacity-55 px-6 text-hover-primary"
          >Team</a
        >
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="text-white opacity-55 pr-0 text-hover-primary"
          >Contact</a
        >
      </div>
      <!--end::Nav-->
    </div>
  </div>
  <!--end::Container-->
</ng-container>
