export const environment = {
  production: false,
  appVersion: 'v717demo2',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://donate-api-demo.mis.cmu.ac.th/api/v1/',
};

//test deploy 001
//test deploy 002
//test deploy 003
