import { DocumentModule } from './../modules/document/document.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../modules/auth/_services/auth.guard';
import { LayoutComponent } from './_layout/layout.component';
import { FoundationAuthGuard } from '../modules/auth/_services/foundation.auth.guard';
import { AssetAuthGuard } from '../modules/auth/_services/asset.auth.guard';
import { PaymentTypeAuthGuard } from '../modules/auth/_services/paymenttype.auth.guard';
import { SignatureAuthGuard } from '../modules/auth/_services/signature.auth.guard';
import { UserRoleAuthGuard } from '../modules/auth/_services/userrole.auth.guard';
import { ProjectAuthGuard } from '../modules/auth/_services/project.auth.guard';
import { DonationAuthGuard } from '../modules/auth/_services/donation.auth.guard';
import { DonateAuthGuard } from '../modules/auth/_services/donate.auth.guard';
import { PointAuthGuard } from '../modules/auth/_services/point.auth.guard';
import { DonationPointAuthGuard } from '../modules/auth/_services/donationpoint.auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      // role
      {
        path: 'userrole',
        loadChildren: () => import('../modules/UserRole/UserRole.module').then((m) => m.UserRoleModule),
        canActivate: [UserRoleAuthGuard],
      },
      {
        path: 'asset',
        loadChildren: () => import('../modules/asset/asset.module').then((m) => m.AssetModule),
        canActivate: [AssetAuthGuard],
      },
      {
        path: 'signature',
        loadChildren: () => import('../modules/signature/signature.module').then((m) => m.SignatureModule),
        canActivate: [SignatureAuthGuard],
      },
      {
        path: 'payment-method',
        loadChildren: () => import('../modules/payment-type/payment-type.module').then((m) => m.PaymentTypeModule),
        canActivate: [PaymentTypeAuthGuard],
      },
      {
        path: 'foundation',
        loadChildren: () => import('../modules/fundation/fundation.module').then((m) => m.FundationModule),
        canActivate: [FoundationAuthGuard],
      },
      {
        path: 'project',
        loadChildren: () => import('../modules/project/project.module').then((m) => m.ProjectModule),
        canActivate: [ProjectAuthGuard],
      },
      {
        path: 'donate',
        loadChildren: () => import('../modules/donate/donate.module').then((m) => m.DonateModule),
        canActivate: [DonateAuthGuard],
      }, {
        path: 'donationgroup',
        loadChildren: () => import('../modules/import-excel/import-excel.module').then((m) => m.ImportExcelModule),
      },
      {
        path: 'donation',
        loadChildren: () => import('../modules/donation/donation.module').then((m) => m.DonationModule),
        canActivate: [DonationAuthGuard],
      },
      {
        path: 'donationpoint',
        loadChildren: () => import('../modules/donation-point/donation-point.module').then((m) => m.DonationPointModule),
        canActivate: [DonationPointAuthGuard],
      },
      {
        path: 'point',
        loadChildren: () => import('../modules/donate-point/donate-point.module').then((m) => m.DonatePointModule),
        canActivate: [PointAuthGuard],
      },
      {
        path: 'organizationcode',
        loadChildren: () => import('../modules/organization-code/organization-code.module').then((m) => m.OrganizationCodeModule),
        canActivate: [PaymentTypeAuthGuard],
      },
      {
        path: 'document',
        loadChildren: () => import('../modules/document/document.module').then((m) => m.DocumentModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('../modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        },
      {
        path: 'builder',
        loadChildren: () =>
          import('./builder/builder.module').then((m) => m.BuilderModule),
      },
      {
        path: 'ecommerce',
        loadChildren: () =>
          import('../modules/e-commerce/e-commerce.module').then(
            (m) => m.ECommerceModule
          ),
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('../modules/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: 'user-profile',
        loadChildren: () =>
          import('../modules/user-profile/user-profile.module').then(
            (m) => m.UserProfileModule
          ),
      },
      {
        path: 'ngbootstrap',
        loadChildren: () =>
          import('../modules/ngbootstrap/ngbootstrap.module').then(
            (m) => m.NgbootstrapModule
          ),
      },
      {
        path: 'wizards',
        loadChildren: () =>
          import('../modules/wizards/wizards.module').then(
            (m) => m.WizardsModule
          ),
      },
      {
        path: 'material',
        loadChildren: () =>
          import('../modules/material/material.module').then(
            (m) => m.MaterialModule
          ),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'error/404',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
