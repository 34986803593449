export const DynamicHeaderMenuConfig = {
  items: [
    {
      title: 'หน้าแรก',
      root: true,
      alignment: 'left',
      icon: '',
      svg: './assets/media/svg/icons/Home/Home-heart.svg',
      page: '/dashboard',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      permission: [0, 4, 5, 6, 8, 10],
    },
    {
      title: 'ตั้งค่าระบบ',
      root: true,
      bullet: 'dot',
      page: '/setting',
      icon: '',
      svg: './assets/media/svg/icons/General/Settings-2.svg',
      permission: [0, 4, 5, 8],
      submenu: [
        {
          title: 'จัดการหน่วยงาน',
          page: 'foundation',
          svg: './assets/media/svg/icons/General/Settings-2.svg',
          permission: [0, 4, 5]
        },
        {
          title: 'จัดการทรัพย์สิน',
          page: 'asset',
          svg: './assets/media/svg/icons/General/Settings-2.svg',
          permission: [0, 4, 8]
        },
        {
          title: 'จัดการประเภทการจ่ายเงิน',
          page: 'payment-method',
          svg: './assets/media/svg/icons/General/Settings-2.svg',
          permission: [0, 4]
        },
        {
          title: 'จัดการลายเซ็นต์',
          page: 'signature/list',
          svg: './assets/media/svg/icons/General/Settings-2.svg',
          permission: [0, 4, 5]
        },
        {
          title: 'จัดการสิทธิการใช้งานระบบ',
          page: '/userrole',
          svg: './assets/media/svg/icons/General/Settings-2.svg',
          permission: [0, 4, 5]
        },
        {
          title: 'จัดการจุดรับบริจาค',
          page: '/point',
          svg: './assets/media/svg/icons/General/Settings-2.svg',
          permission: [0, 4, 5, 7]
        },
        {
          title: 'จัดการรหัสหน่วยงาน',
          page: '/organizationcode',
          svg: './assets/media/svg/icons/General/Settings-2.svg',
          permission: [0, 4]
        }
      ]
    },
    {
      title: 'จุดรับบริจาค',
      root: true,
      bullet: 'dot',
      page: '/donationpoint',
      icon: '',
      svg: './assets/media/svg/icons/Communication/Donation.svg',
      permission: [0, 4, 5, 7, 8, 9],
    },
    {
      title: 'โครงการรับบริจาค',
      root: true,
      bullet: 'dot',
      page: '/project',
      icon: '',
      svg: './assets/media/svg/icons/Communication/Dial-numbers.svg',
      permission: [0, 4, 5, 6],
      submenu: [
        {
          title: 'รายชื่อโครงการรับบริจาค',
          svg: './assets/media/svg/icons/Communication/Dial-numbers.svg',
          page: '/project/list',
          permission: [0, 4, 5]
        }, {
          title: 'จัดการโครงสร้างบัญชี / BillerID',
          svg: './assets/media/svg/icons/Communication/Dial-numbers.svg',
          page: '/project/waiting',
          permission: [0, 4]
        }, {
          title: 'อนุมัติโครงการรับบริจาค',
          svg: './assets/media/svg/icons/Communication/Dial-numbers.svg',
          page: '/project/approve',
          permission: [0, 4, 5, 6]
        }
      ]
    },
    {
      title: 'ผู้บริจาค',
      root: true,
      bullet: 'dot',
      page: '/donate',
      icon: '',
      svg: './assets/media/svg/icons/General/User.svg',
      permission: [0, 4, 5, 7, 8, 9],
      submenu: [
        {
          title: 'บุคคลทั่วไป',
          svg: './assets/media/svg/icons/General/User.svg',
          page: '/donate/user',
          permission: [0, 4, 5, 7, 8, 9]
        }, {
          title: 'นิติบุคคล บริษัท/ห้างร้าน/องค์กร',
          svg: './assets/media/svg/icons/General/User.svg',
          page: '/donate/business',
          permission: [0, 4, 5, 7, 8, 9]
        }
      ]
    },
    {
      title: 'การบริจาค',
      root: true,
      bullet: 'dot',
      icon: '',
      svg: './assets/media/svg/icons/Shopping/Dollar.svg',
      permission: [0, 4, 5, 7, 8, 9],
      submenu: [
        {
          title: 'ค้นหาการบริจาค',
          svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
          page: '/document/list',
          permission: [0, 4, 6, 5, 7, 8, 9],
        },
        {
          title: 'บันทึกการบริจาค',
          svg: './assets/media/svg/icons/Shopping/Dollar.svg',
          page: '/donation',
          permission: [0, 4, 5, 7, 8, 9]
        },
        {
          title: 'บันทึกการบริจาครายกลุ่ม',
          svg: './assets/media/svg/icons/Shopping/Dollar.svg',
          page: '/donationgroup',
          permission: [0, 4, 5, 7, 8, 9]
        },
        // {
        //   title: 'รายการบริจาคทรัพย์สิน',
        //   svg: './assets/media/svg/icons/Shopping/Dollar.svg',
        //   page: '/dondonationte/list',
        // }
      ]
    },
    // {
    //   title: 'การรับบริจาคทรัพย์สิน',
    //   root: true,
    //   bullet: 'dot',
    //   page: '/donateasset',
    //   icon: '',
    //   svg: './assets/media/svg/icons/Home/Home-heart.svg',
    //   submenu: [
    //     {
    //       title: 'บันทึกข้อมูลการบริจาค',
    //       bullet: 'dot',
    //       svg: './assets/media/svg/icons/Home/Home-heart.svg',
    //       page: '/donateasset/add',
    //     },{
    //       title: 'รายการบริจาคทรัพย์สิน',
    //       bullet: 'dot',
    //       svg: './assets/media/svg/icons/Home/Home-heart.svg',
    //       page: '/donateasset/list',
    //     }
    //   ]
    // },
    // {
    //   title: 'อนุมัติโครงการรับบริจาค',
    //   root: true,
    //   bullet: 'dot',
    //   page: '/allowproject',
    //   icon: '',
    //   svg: './assets/media/svg/icons/Communication/Incoming-box.svg',
    //   submenu: [
    //     {
    //       title: 'โครงการรอการอนุมัติ',
    //       svg: './assets/media/svg/icons/Communication/Incoming-box.svg',
    //       page: '/allowproject/waiting',
    //     },{
    //       title: 'รายการโครงการรับบริจาค',
    //       svg: './assets/media/svg/icons/Communication/Incoming-box.svg',
    //       page: '/allowproject/list',
    //     }
    //   ]
    // },
    {
      title: 'รายงาน',
      root: true,
      bullet: 'dot',
      page: '/report',
      icon: '',
      svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
      permission: [0, 4, 6, 5, 7, 8, 9],
      submenu: [ 
        {
          title: 'รายงานส่งสรรพากร',
          svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
          page: '/document/revenue',
          permission: [0, 4, 6, 5, 7, 8, 9],
        },
        {
          title: 'รายงานสรุปการรับบริจาค',
          svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
          page: '/document/donation',
          permission: [0, 4, 6, 5, 7, 8, 9],
        },
        {
          title: 'รายงานการบริจาคผ่านเคาน์เตอร์',
          svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
          page: '/document/counter',
          permission: [0, 4, 6, 5, 7, 8, 9],
        },
        {
          title: 'รายงานการบริจาคผ่าน QR Code',
          svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
          page: '/document/qr-code',
          permission: [0, 4, 6, 5, 7, 8, 9],
        },
        {
          title: 'รายงานสรุปยอดรวมเงินโอนให้ส่วนงาน',
          svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
          page: '/document/report-summary-transferred',
          permission: [0, 4, 6, 5, 7, 8, 9],
        },
        // {
        //   title: 'รายชื่อโครงการรับบริจาค',
        //   svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
        //   page: '/report/listProject',
        // }, {
        //   title: 'รายงานการบริจาค',
        //   svg: './assets/media/svg/icons/Shopping/Chart-pie.svg',
        //   page: '/report/listDonate',
        // }
      ]
    }
  ]
};
