import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CmuApi } from '../modules/auth/_models/CMUApi';

const API_ENDPLOINT = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpClient) { }

  getSearchProject(param: any): Observable<CmuApi> {
    let query = `?FoundationId=${param.FoundationId}`;
    query += `&OrganizationLevel2IdMis=${param.OrganizationLevel2IdMis}`;
    query += `&ProjectName=${param.ProjectName}`;
    query += `&MoneyDestinationType=${param.MoneyDestinationType}`;
    query += `&StatusForDonation=${param.StatusForDonation}`;
    query += `&StatusForQr=${param.StatusForQr}`;
    query += `&Status=${param.Status}`;
    query += `&StatusApprove=${param.StatusApprove}`;

    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'project/search' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getProject(FoundationId: number, ProjectId: number): Observable<CmuApi> {
    const query = `?FoundationId=${FoundationId}&ProjectId=${ProjectId}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'project/fullinfo' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getProjectByIdLanding(FoundationId: number, ProjectId: number): Observable<CmuApi> {
    const query = `?FoundationId=${FoundationId}&ProjectId=${ProjectId}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'project/fullinfo/landing' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }
  
  getProjectFoundation(FoundationId: number): Observable<CmuApi> {
    const query = `?FoundationId=${FoundationId}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'project/foundation/all' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getProjectSearchDropdown(FoundationId: number, Status: string): Observable<CmuApi> {
    let query = `?FoundationId=${FoundationId}`
    query += `&Status=${Status}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'project/searchdropdown' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getProjectsRandom(Limit: number): Observable<CmuApi> {
    let query = `?Limit=${Limit}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'project/landingPage' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getProjectByFoundationId(StartSortId: number, Limit: number, FoundationId: number): Observable<CmuApi> {
    let query = `?StartSortId=${StartSortId}`;
    query += `&Limit=${Limit}`;
    query += `&FoundationId=${FoundationId}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'project/viewAll' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getMoreProjects(StartSortId: number, Limit: number, FoundationId: number, ProjectName: string, SortByTransaction:string, SortByDonateMoney:string): Observable<CmuApi> {
    let query = `?StartSortId=${StartSortId}`;
    query += `&Limit=${Limit}`;
    query += `&FoundationId=${FoundationId}`;
    query += `&ProjectName=${ProjectName}`;
    // sort ยังทำไม่เสร็จ
    query += `&SortByTransactin=${SortByTransaction}`;
    query += `&SortByDonateMoney=${SortByDonateMoney}`;
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'project/viewAll' + query)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  addProject(obj: any): Observable<CmuApi> {
    return this.http
    .post<CmuApi>(API_ENDPLOINT + 'project/fullinfo', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateProject(obj: any): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'project/fullinfo', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateStatusForDonation(obj: any): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'project/setstatusfordonation', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateProjectStatus(obj: any): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'project/setstatus', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateQRStatus(obj: any): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'project/setstatusforQR', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateProjectStatusApprove(obj: any): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'project/setstatusapprove', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  getBackList(): Observable<CmuApi> {
    return this.http
    .get<CmuApi>(API_ENDPLOINT + 'ref/bank/banklist' )
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  deleteLogo(FoundationId: number, ProjectId: number): Observable<CmuApi> {
    let query = `?_foundationID=${FoundationId}`;
    query += `&_projectID=${ProjectId}`;

    return this.http
    .delete<CmuApi>(API_ENDPLOINT + 'project/delProjectLogoByID' + query )
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  deleteImage(FoundationId: number, ProjectId: number, ImageId: number): Observable<CmuApi> {
    let query = `?_foundationID=${FoundationId}`;
    query += `&_projectID=${ProjectId}`;
    query += `&_photoID=${ImageId}`;
    return this.http
    .delete<CmuApi>(API_ENDPLOINT + 'project/delProjectPhotoByID' + query )
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updatePaymentMethod(obj: any): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'project/PaymentMethod', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }

  updateSegment(obj: any): Observable<CmuApi> {
    return this.http
    .put<CmuApi>(API_ENDPLOINT + 'project/segment', obj)
    .pipe(map(data => CmuApi.fromObject(data)));
  }
}
