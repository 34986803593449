export class CmuApi {
  public message: string;
  public data: any;

  constructor(apiData?: any) {
    if (apiData) {
      this.message = apiData.message;
      this.data = apiData.data;
    }
  }

  static fromObject(obj: any): CmuApi {
    return new CmuApi({ message: obj.message, data: obj.data });
  }

  public isSuccess(): boolean {
    if (this.message.toString().toLowerCase() === 'true') return true;
    return false;
  }
}
