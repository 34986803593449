import { Component, Input, OnInit } from '@angular/core';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() control?:any;
  @Input() nameField?:any;
  @Input() submitted?:any;
  // @Input() nameFieldConfirm?:any;

  message: any;

  constructor() { }


  ngOnInit(): void {
    this.message = this.errorMessage()
    this.control.valueChanges.subscribe(value => {
      this.message = this.errorMessage()
    })
    // this.control.touched.subscribe(value => {
    //   this.message = this.errorMessage()
    // })
  }



  errorMessage(){
    for(let propertyName in this.control?.errors){ // call service if control has error
      if(this.control?.errors.hasOwnProperty(propertyName)){
        return ValidationService.getValidatorErrorMessage(propertyName, this.control?.errors[propertyName], this.nameField)
        // , this.nameFieldConfirm
      }
    }
    return null;
  }



}


// <ng-template
//   #formError
//   let-control="control"
//   let-message="message"
//   let-validation="validation"
// >
//   <ng-container
//     *ngIf="control.hasError(validation) && (control.dirty || control.touched)"
//   >
//     <div class="fv-plugins-message-container">
//       <div class="fv-help-block">
//         {{ message }}
//       </div>
//     </div>
//   </ng-container>
// </ng-template>